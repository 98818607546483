import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { BsFillPersonFill } from "react-icons/bs";

const Socials = () => {
  const links = [
    {
      id: 1,
      child: (
        <>
          Linkedin <FaLinkedin size={30} />{" "}
        </>
      ),
      href: "https://www.linkedin.com/in/josue-garza-b8b47623b/",
      style: "rounded-tr-md",
    },
    {
      id: 2,
      child: (
        <>
          Github <FaGithub size={30} />{" "}
        </>
      ),
      href: "https://github.com/GarzaNate",
    },
    {
      id: 3,
      child: (
        <>
          Mail <FiMail size={30} />{" "}
        </>
      ),
      href: "mailto:nategarza2410@gmail.com",
    },
    {
      id: 4,
      child: (
        <>
          Resume <BsFillPersonFill size={30} />{" "}
        </>
      ),
      href: "/resume.pdf",
      style: "rounded-br-md",
      download: true,
    },
  ];

  return (
    <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
      <ul>
        {links.map(({id, child, href, style, download}) => (
          <li key={id} className={"flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-gray-500" + " " + style}>
            <a
              href={href}
              className="flex justify-between items-center w-full text-white" download={download} target='_blank' rel="noreferrer"
            >
                {child}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Socials;
