import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

const NavBar = () => {
  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: "Home",
      href: "/#/"
    },
    {
      id: 2,
      link: "About",
      href: "/#/about"
    },
    {
      id: 3,
      link: "Portfolio",
      href: "/#/portfolio"
    },
    {
      id: 4,
      link: "Experience",
      href: "/#/experience"
    },
    {
      id: 5,
      link: "Contact",
      href: "/#/contact"
    },
  ];

  return (
    <div className="flex justify-between items-center w-full h-20 text-white bg-black px-4">
      <div>
        <h1 className="text-5xl font-signature ml-2">Josue</h1>
      </div>

      <ul className="hidden md:flex">
        {links.map(({ id, link, href }) => (
          <li
            key={id}
            className="px-4 cursor-pointer font-medium text-gray-500 hover:scale-105 duration-200"
          >
            <a href={href}>
            {link}
            </a>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {nav && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500">
          {links.map(({ id, link, href }) => (
            <li key={id} className="px-4 cursor-pointer py-6 text-4xl">
              <a href={href}>
              {link}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
